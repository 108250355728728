<template>
  <div class="p-0">
    <v-overlay :value="!exibirSuperset" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>

    <div id="supersetMount" class="mt-5"></div>
  </div>
</template>

<script>
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Utils from "@/core/utils/Utils";
import axios from "axios";
import SupersetSerices from "../../../../core/services/SupersetServices";

export default {
  name: "dashboard",
  data() {
    return {
      supersetDomain: "", //process.env.VUE_APP_SUPERSET_URL ?? "http://localhost",
      idDashboard: "",
      token: "",
      interval: null,
      exibirSuperset: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Estatísticas" }]);
    let newConfig = JSON.parse(JSON.stringify(config));
    this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    newConfig.header.self.display = false;
    newConfig.subheader.display = false;
    newConfig.styleContetnt = { padding: "0" };
    newConfig.styleContainer = {
      "max-width": "100%",
      margin: 0,
      "padding-right": 0
    };
    newConfig.wrapper = { "padding-top": 0 };
    newConfig.scrollTop.self.display = false;
    newConfig.aside.self.minimize.hoverable = true;
    this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);

    this.inicializar();
  },

  beforeDestroy() {
    clearInterval(this.interval);
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
  },

  methods: {
    async inicializar() {
      this.supersetEmbeddedSdk = document.createElement("script");
      this.supersetEmbeddedSdk.setAttribute(
        "src",
        "https://unpkg.com/@superset-ui/embedded-sdk@0.1.0-alpha.9/bundle/index.js"
      );
      document.head.appendChild(this.supersetEmbeddedSdk);

      this.token = await this.fetchGuestTokenFromBackend();

      this.$forceUpdate();
      window.supersetEmbeddedSdk.embedDashboard({
        id: this.idDashboard,
        supersetDomain: this.supersetDomain,
        mountPoint: document.getElementById("supersetMount"),
        fetchGuestToken: () => this.token,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true
          }
        }
      });

      this.iniciarAtualizacaoToken();
    },
    async fetchGuestTokenFromBackend() {
      let data = await SupersetSerices.pegarTokenEstatistica();
      this.exibirSuperset = true;
      this.supersetDomain = data.supersetDomain;
      this.idDashboard = data.idDashboard;

      return data.token;
    },
    iniciarAtualizacaoToken() {
      this.interval = Utils.setInterval(async () => {
        this.token = await this.fetchGuestTokenFromBackend();
      }, 240000);
    },
    iframeSupersetCarregado() {
      iframeResize({ log: true }, "#supersetMount > iframe");
    }
  }
};
</script>

<style>
#supersetMount > iframe {
  width: 100%;
  height: 100vh;
  border: 0;
  overflow: hidden;
}

body {
  overflow: hidden; /* Hide scrollbars */
}
</style>
