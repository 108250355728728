import ApiService from "@/core/services/api.service";

export default class SupersetServices {
  static async pegarTokenBi(idDashboard) {
    let { data } = await ApiService.get(
      `/bi/pegar-token-superset/${idDashboard}`,
      "",
      false
    );
    return data;
  }

  static async pegarTokenEstatistica() {
    let { data } = await ApiService.get(
      "/dashboard/token-estatistica-superset",
      "",
      false
    );
    return data;
  }
}
